
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Day from "@/components/therapistCalendar/Day.vue";
import { Session } from "@/interfaces/therapy";
import { TherapistAvailableDate } from "@/interfaces/visit";
import { getMonthsForLocale } from "./helpers";
import { get } from "@/api";
import Spinner from "@/components/Spinner.vue";
import i18n from "@/i18n";
const pageKey = "calendar";

@Component({
  components: {
    Day,
    Spinner,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class TherapistCalendar extends Vue {
  @Prop()
  type!: string;

  displayMonth = "";
  calendarDate!: Date;
  daysInMonth = 0;
  dayShift = 0;
  therapistDates: Record<
    string,
    Array<TherapistAvailableDate | Array<Session> | undefined>
  > = {};
  loaded = false;

  async loadTherapistDates() {
    let data;
    try {
      if (this.type == "available") {
        const response = await get.therapistAvailableDates();
        data = response.data;
        this.loaded = true;
        this.$store.dispatch("getTherapistGroups");
      }
      if (this.type == "picked") {
        const response = await get.sessions();
        data = response.data.filter((session) => !session.cancelled);
        this.loaded = true;
      }
      this.createDatesDict(data);
    } catch (error) {
      console.log(error);
    }
  }
  createDatesDict(body: any) {
    const dict: Record<string, TherapistAvailableDate[]> = {};
    body.forEach((date: any) => {
      const dateRepresentation = new Date(date.span.start).toDateString();
      if (dict[dateRepresentation]) dict[dateRepresentation].push(date);
      else dict[dateRepresentation] = [date];
    });
    this.$store.commit("setTherapistSpans", dict);
    this.therapistDates = this.$store.state.calendar.therapistSpans;
  }
  displayCurrentMonthDates(direction: number) {
    const monthNames = getMonthsForLocale("long", true);
    const currentMonthIndex = this.calendarDate.getMonth();
    this.calendarDate.setMonth(currentMonthIndex + direction);
    const newMonthIndex = this.calendarDate.getMonth();
    this.displayMonth = monthNames[newMonthIndex];
    const daysToShift = new Date(this.calendarDate);
    daysToShift.setDate(1);
    this.dayShift = daysToShift.getDay() - 1;
    this.daysInMonth = new Date(
      this.calendarDate.getFullYear(),
      this.calendarDate.getMonth() + 1,
      0
    ).getDate();
  }
  openModalNewDate() {
    this.$openModal({
      component: "CalendarModal",
      closeButton: true,
      props: {
        mode: "addNew",
        dateToEdit: "",
        modalDate: new Date(),
        reloadSpans: this.loadTherapistDates,
      },
    });
  }
  generateKeyForDay(day: number): string {
    const calendar_date = new Date(this.calendarDate.getTime());
    calendar_date.setDate(day);
    return calendar_date.toDateString();
  }

  @Watch("type")
  onCalendarChange() {
    this.loadTherapistDates();
  }

  mounted() {
    this.calendarDate = new Date();
    this.displayCurrentMonthDates(0);
    this.loadTherapistDates();
  }
}
