
import { Component, Vue, Prop } from "vue-property-decorator";
import { Therapy, Visit } from "@/interfaces";
import { displaySpanRange } from "@/views/therapistCalendar/helpers";

@Component({
  methods: {
    displaySpanRange,
  },
})
export default class Day extends Vue {
  @Prop()
  day!: number;
  @Prop()
  spanList!:
    | Record<string, Array<Visit.AvailableDate>>
    | Record<string, Array<Therapy.Session>>;
  @Prop()
  reloadSpans!: Function;
  @Prop()
  calendarType!: string;
  @Prop()
  calendarDate!: Date;

  disposedSpans: Array<Visit.AvailableDate | Therapy.Session> = [];

  get disposeSpans() {
    if (!this.spanList) return false;

    const date = new Date(this.calendarDate.getTime());
    date.setDate(this.day);

    if (this.spanList[date.toDateString()]) {
      this.disposedSpans = this.spanList[date.toDateString()].sort(function(
        span1: Visit.AvailableDate | Therapy.Session,
        span2: Visit.AvailableDate | Therapy.Session
      ) {
        if (span1.span.start < span2.span.start) return -1;
        else if (span1.span.start > span2.span.start) return 1;
        return 0;
      });
      return true;
    }
    return false;
  }

  openModal(mode: string, dateToEdit: number | null) {
    const modalDate = new Date(this.calendarDate.getTime());
    modalDate.setDate(this.day);

    const settingsForAvailableSpans = {
      mode: mode,
      dateToEdit: dateToEdit,
      modalDate: modalDate,
      reloadSpans: this.reloadSpans,
    };

    const settingsForBookedSessions = {
      modal_date: new Date(modalDate),
      reloadSpans: this.reloadSpans,
    };

    this.$openModal({
      component:
        this.calendarType == "available"
          ? "CalendarModal"
          : "CalendarBookedModal",
      closeButton: true,
      props:
        this.calendarType == "available"
          ? settingsForAvailableSpans
          : settingsForBookedSessions,
    });
  }

  isTodayDate(day_number: number): boolean {
    const current_date = new Date();
    const calendar_date = new Date(this.calendarDate.getTime());
    calendar_date.setDate(day_number);
    return current_date.toDateString() == calendar_date.toDateString();
  }
}
